import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import EmailCaptureForm from "../components/EmailCaptureForm"
import Seo from "../components/seo"

import Layout from "../components/layout"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo
      title="Home — Social Impact Magazine"
      keywords={[
        `Social Impact Magazine`,
        `Entrepreneurship Magazine`,
        `Entrepreneur Magazine`,
      ]}
    />
    <main className="main" role="document">
      <section className="header-hero  [ section  section--header ]">
        <div className="container">
          <header className="row  header-hero__header">
            <div className="[ column  hidden--mobile  column--lg-2 ]  shell-flex-child--align-bottom">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--primary ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entr-woman-looking-to-the-right.jpg/" alt="Woman looking to the right" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
            <div className="[ column column--xs-6  column--lg-4 ]  margin-bottom--6  margin-top--4">
              <hgroup className="headlines  position--relative">
                <h1 className="[ headline  headline--alpha ]  margin-bottom--2  night">
                  Inbox&nbsp;Mag
                </h1>
                <h4 className="[ headline  headline--beta  headline--on-top ]">
                  Striving to inspire and
                  <br /> connect changemakers,
                  <br /> right in your inbox
                </h4>
              </hgroup>
              <br />
              <br />
              <EmailCaptureForm className="email-updates-form  [ newsletter-signup  newsletter-signup--large ]  width--100  margin-bottom--0" />
              <div className="[ description  description--small ]">
                <p>
                No buzzwords, hype or FOMO. Instead: Stories, Knowledge, Insights, Tools, Community
                </p>
              </div>
            </div>
          </header>
        </div>
      </section>
      <section
        idName="introduction"
        className="hero  [ section  section--hero ]"
      >
        <div className="container">
          <header className="row  hero__header">
            <div className="[ column  column--xs-4  hidden--desktop ]">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--primary ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/entr-woman-looking-to-the-right.jpg/" alt="Woman looking to the right" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
            <div className="[ column  column--xs-2  column--lg-2 ]">
              <div className="margin-right--2  position--relative">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/man-with-sunglasses4.jpg/" alt="Man with sunglasses" placeholder="blurred" />
                  </div>
                </figure>
                <div className="headlines--alternative  absolute-margin-top--2  hidden--mobile">
                  <h5 className="[ headline  headline--alternative ]">
                    <span className="headline--alternative__transform">
                      Introduction
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-1  hidden--desktop ]  position--relative">
              <div className="headlines--alternative  absolute-margin-top--2">
                <h5 className="[ headline  headline--alternative ]">
                  <span className="headline--alternative__transform">
                    Introduction
                  </span>
                </h5>
              </div>
            </div>
            <div className="[ column  column--xs-5  column--lg-4 ]">
              <div className="[ description  description--introduction ]  margin-bottom--20  margin-top--20">
                <p>
                  A 'inbox magazine' that is dedicated to increase the
                  awareness of purpose-driven people who are creating products,
                  services and ideas that shape the future for good.
                </p>
                <p>
                  In intimate conversations entrepreneurs, thinkers, activists
                  and researchers reveal how they sometimes struggle, overcome
                  failures and thrive in order to gain success.
                </p>
              </div>
            </div>
          </header>
        </div>
      </section>
      <section className="what-is-social-impact  [ section  section--neutral ]">
        <div className="container">
          <aside className="row  what-is-social-impact__content  margin-bottom--10  shell-flex-parent--inline">
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top ]">
                <hgroup className="headlines  position--relative  margin-top--2  margin-bottom--2">
                  <span className="[ headline  headline--alpha-column ]">
                    01
                  </span>
                  <h3 className="[ headline  headline--gamma-column  headline--on-top ]">
                    Readers first
                  </h3>
                </hgroup>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    Every story, method and tool we provide serves our readers.
                    It’s incredible importance to us for us that our readers get
                    out high-quality lessons and encouragement to boost their
                    own social impact. Together we can create an enriched
                    environment and sustainable future for everyone, not just
                    for a privileged few.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top ]">
                <hgroup className="headlines  position--relative  margin-top--2  margin-bottom--2">
                  <span className="[ headline  headline--alpha-column ]">
                    02
                  </span>
                  <h3 className="[ headline  headline--gamma-column  headline--on-top ]">
                    Empathy and empowerment
                  </h3>
                </hgroup>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    Empowering everyone equally—no differentiation between
                    gender, race or social origin— is powerful. We belief it’s
                    the key to economic growth, political stability, and social
                    transformation. That is why we promoting empathy and
                    compassion by bringing firstly the human side in each and
                    every story.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top ]">
                <hgroup className="headlines  position--relative  margin-top--2  margin-bottom--2">
                  <span className="[ headline  headline--alpha-column ]">
                    03
                  </span>
                  <h3 className="[ headline  headline--gamma-column  headline--on-top ]">
                    Thriving for diversity
                  </h3>
                </hgroup>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    We we strive towards to publish articles that portrays all
                    perspectives, we strive towards a holistic form of
                    journalism, making an effort to capture all kinds of views.
                    It is always relevant for us to have a diverse group of
                    contributors. We want to foster an open-minded, global
                    culture.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-offset-1  column--xs-5  column--sm-offset-0  column--sm-3  column--lg-2 ]">
              <div className="[ line  line--border-top ]">
                <hgroup className="headlines  position--relative  margin-top--2  margin-bottom--2">
                  <span className="[ headline  headline--alpha-column ]">
                    04
                  </span>
                  <h3 className="[ headline  headline--gamma-column  headline--on-top ]">
                    Transparent process
                  </h3>
                </hgroup>
                <div className="[ description  description--small ]  margin-bottom--6">
                  <p>
                    We want to be as transparent as possible in the production
                    of this magazine. Our commitment to sharing our own process
                    and decisions is another way to provide value to our
                    readers. Do you have any questions? You can always write an
                    email to us. We usually reply in a feasible time
                    approximately within 24 hours.
                  </p>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-4  column--lg-4 ]  shell-flex-child--align-bottom  impression--column-fix">
              <div className="margin-right--2">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--neutral ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/woman-with-phone2.jpg/" alt="Woman with phone" placeholder="blurred" />
                  </div>
                </figure>
              </div>
            </div>
          </aside>
        </div>
      </section>
      <section
        idName="email"
        className="email-updates  [ section  section--call-to-action ]"
      >
        <div className="container">
          <div className="row">
            <div className="[ column  column--xs-6  column--sm-2  column--lg-2 ]">
              <div className="margin-right--2  position--relative">
                <figure className="[ impression  impression--small ]">
                  <div className="[ color-overlay  color-overlay--call-to-action ]" />
                  <div className="desaturate">
                    <StaticImage src="../images/man-lay-wall.jpg/" alt="Man lay on wall" placeholder="blurred" />
                  </div>
                </figure>
                <div className="headlines--alternative  absolute-margin-top--2  hidden--mobile">
                  <h5 className="[ headline  headline--alternative ]">
                    <span className="headline--alternative__transform">
                      Subscribe
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="[ column  column--xs-6  column--sm-4  column--lg-4 ]  margin-bottom--10">
              <hgroup className="headlines  margin-top--20  margin-bottom--4">
                <h2 className="[ headline  headline--beta ]">
                  A thoughtful weekly newsletter helping you feel inspired, learn new traits, and think from new perspectives
                </h2>
              </hgroup>
              <EmailCaptureForm className="email-updates-form  [ newsletter-signup  newsletter-signup--large ]  width--100  margin-bottom--0" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default IndexPage